import { NavigationItem } from "./types";

export const guestSideNavigationItems: NavigationItem[] = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Find Work",
    path: "/explore-categories",
  },
  {
    label: "About",
    path: "/about",
  },
  {
    label: "Become an Employer",
    path: "/employer-registration",
  },
  {
    label: "Help",
    path: "/faq",
  },
  {
    label: "About Us",
    path: "/about",
  },
  {
    label: "Login",
    path: "/user-entry/signup?returnTo=/",
  },
];

export const signedInSideNavigationItems: NavigationItem[] = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "My Dashboard",
    path: "/task/gigHistory",
  },
  {
    label: "Find Work",
    path: "/explore-categories",
  },
  {
    label: "Wallet",
    path: "/wallet",
  },
  {
    label: "Help",
    path: "/faq",
  },
  {
    label: "About Us",
    path: "/about",
  },
];

export const signedInSupervisorSideNavigation: NavigationItem[] = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "My Dashboard",
    path: "/task/gigHistory",
  },
  {
    label: "Manage Subordinates",
    path: "/manage-subordinates",
  },
  {
    label: "Find Work",
    path: "/explore-categories",
  },
  {
    label: "Wallet",
    path: "/wallet",
  },
  {
    label: "Help",
    path: "/faq",
  },
  {
    label: "About Us",
    path: "/about",
  },
];
export const auditSideNavigationItems: NavigationItem[] = [
  {
    label: "Home",
    path: "/",
  },
];
