import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { Box, Typography, CircularProgress } from "@mui/material";

const VerifyEmail = () => {
  const history = useNavigate();
  const { token } = useParams();
  const { loginWithEmailToken } = useAuthContext();

  useEffect(() => {
    const verify = async () => {
      try {
        if (!token) return;
        await loginWithEmailToken(token);
        history("/");
      } catch (error) {
        if (typeof error === "object" && error !== null && "status" in error) {
          const errorWithStatus = error as { status: number };
          if (errorWithStatus.status === 401) {
           history("/")
          } else {
            console.error("An error occurred:", error);
          }
        } else {
          console.error("Unknown error:", error);
        }
      }
    };
    verify();
  }, [history, loginWithEmailToken, token]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 61px)"
      width="100%"
      bgcolor="#f5f5f5"
    >
      <CircularProgress size={60} thickness={4} color="primary" />
      <Typography
        variant="h5"
        fontWeight="bold"
        color="textPrimary"
        mt={3}
        textAlign="center"
      >
        Verifying your email, please wait...
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={1} textAlign="center">
        We’re setting up your account. This will only take a moment.
      </Typography>
    </Box>
  );
};

export default VerifyEmail;
