import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState, useCallback, useRef } from "react";
import { Close } from "@mui/icons-material";
import { useAuthContext } from "../../providers/AuthProvider";
import { uploadProfilePicture } from "@gogig-in/gogig-clients";
import CustomLoading from "../custom/Loading";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

interface CroppedAreaPixels {
  x: number;
  y: number;
  width: number;
  height: number;
}

const ProfilePictureComponent = () => {
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userDetails, authHeader, resetUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedAreaPixels | null>(null);

  const handleCropComplete = useCallback(
    (_: any, croppedAreaPixels: CroppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsModalOpen(true);
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      if (selectedFile) {
        const blobUrl = URL.createObjectURL(selectedFile);
        setPreviewImage(blobUrl);
        setIsPreviewAvailable(true);
      }
    }
    // Reset the input value to null after processing the file using ref
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleUpload = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    if (!file || !croppedAreaPixels) {
      console.error("Please select a file and crop the image.");
      return;
    }

    const croppedImage = await getCroppedImg(previewImage!, croppedAreaPixels);

    if (!croppedImage) {
      console.error("Cropped image is null");
      return;
    }

    const formData = new FormData();
    formData.append("file", croppedImage, "cropped.jpg");

    try {
      if (authHeader) {
        await uploadProfilePicture(formData, authHeader);
        await resetUser();
        setIsLoading(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsLoading(false);
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name: string) {
    const [firstName, lastName] = name.trim().split(" ");
    const avatarText = (
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "")
    ).toUpperCase();
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "24px", // Set the font size to 24
      },
      children: avatarText || "",
    };
  }

  return (
    <>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <Box
          style={{
            display: "flex",
          }}
        >
          {userDetails && (
            <Avatar
              {...stringAvatar(
                userDetails.firstName + " " + userDetails.lastName
              )}
              src={userDetails.profilePicture}
              sx={{
                fontSize: "54px",
                width: "150px",
                height: "150px",
                backgroundColor: stringToColor(
                  userDetails.firstName + " " + userDetails.lastName
                ),
              }}
            />
          )}

          <IconButton
            style={{
              width: "150px",
              height: "150px",
              position: "absolute",
            }}
          >
            <Avatar
              sx={{
                marginLeft: "108px",
                marginTop: "108px",
                cursor: "default",
              }}
            >
              <label
                htmlFor="file-input"
                style={{
                  cursor: "pointer",
                }}
              >
                <CameraAltOutlinedIcon />
              </label>
              <input
                id="file-input"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={inputRef}
              />
            </Avatar>
          </IconButton>
        </Box>
      )}
      {userDetails && !isMobileView ? (
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>Crop Image</div>
            <IconButton
              sx={{
                color: "black",
              }}
              onClick={handleCloseModal}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {isPreviewAvailable && (
              <div style={{ width: "100%", height: 300, position: "relative" }}>
                <Cropper
                  image={previewImage!}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={handleCropComplete}
                />
              </div>
            )}
            <Button
              variant="contained"
              size="small"
              disabled={!file}
              onClick={handleUpload}
            >
              Upload Profile Picture
            </Button>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", marginTop: "10px" }}
            >
              (Only .jpg, .jpeg, .png files are allowed)
            </Typography>
          </DialogContent>
        </Dialog>
      ) : (
        userDetails && (
          <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography sx={{ marginTop: "18px" }} variant="h6">
                Crop Image
              </Typography>
              <IconButton
                sx={{
                  color: "black",
                }}
                onClick={handleCloseModal}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {isPreviewAvailable && (
                <div
                  style={{ width: "100%", height: 300, position: "relative" }}
                >
                  <Cropper
                    image={previewImage!}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={handleCropComplete}
                  />
                </div>
              )}
              <Button
                variant="contained"
                size="small"
                disabled={!file}
                onClick={handleUpload}
                sx={{ marginTop: "10px" }}
              >
                Upload Profile Picture
              </Button>
              <Typography
                variant="body2"
                sx={{ textAlign: "center", marginTop: "5px" }}
              >
                (Only .jpg, .jpeg, .png files are allowed)
              </Typography>
            </DialogContent>
          </Dialog>
        )
      )}
    </>
  );
};

export default ProfilePictureComponent;
