import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SignUpValues } from "../components/signUp/SignUpValues";
import { AuthApiBaseUrl, SpotrueClientId,clientId } from "./baseUrls";

export async function createUsers(
  formValues: SignUpValues,
  authHeader: string
) {
  const target = process.env.REACT_APP_TARGET;
  const url = `${AuthApiBaseUrl}/createUser?clientId=${target==="spotrue"?SpotrueClientId:clientId}`;

  return fetchWrapper(url, {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: authHeader,
    },
  });
}