import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import CompanyRegistration from "./components/company&EmployerRegistration/CompanyEmployerRegistration";
import GigDetailsPage from "./pages/GigDetails";
import EmployerGigList from "./components/employerGigList/EmployerGigList";
import GigDetailsEmp from "./components/employerGigList/GigDetailsEmp";
import "./styles/globals.css";
import GigHistoryList from "./components/gigDashboardPage/GigHistory";
import VendorList from "./pages/vendorList/VendorList";
import VendorDetails from "./components/vendorDetail/VendorDetails";
import AboutPageComponent from "./pages/AboutPageComponent";
import FAQPageComponent from "./pages/FAQPageComponent";
import WalletPage from "./pages/wallet/Wallet";
import SupplementsPage from "./components/endUserLandingPage/supplements";
import EmployerRegistrationForm from "./components/home/EmployerRegistrationForm";
import GigStatus from "./components/endUserLandingPage/GigTutorial";
import DailyEarnings from "./components/dailyEarnings/DailyEarnings";
import WithdrawPage from "./components/transactions/WithdrawPage";
import OTPPage from "./components/transactions/OTPPage";
import WithdrawStatus from "./components/transactions/WithdraawStatus";
import CreatePin from "./components/transactions/CreatePin";
import UserEntry from "./components/userEntryFlow/UserEntrance";
import UserEntryForm from "./components/userEntryFlow/UserEntryForms";
import VerifyEmail from "./components/VerifyEmail";
import GoToEmailInbox from "./pages/GoToEmailInbox";
import ProfileCreatedPage from "./components/ProfileCreatedPage";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import DefaultContainer from "./DefaultContainer";
import SlimContainer from "./SlimContainer";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import NewPasswordPage from "./components/NewPasswordPage/NewPasswordPage";
import TaskContainer from "./TaskContainer";
import PdfTestComponent from "./components/PdfTestComponent";
import PaymentMethod from "./components/transactions/PaymentOptions";
import AadharPanForm from "./components/AadharPanForm";
import TaskReview from "./components/vendorResult/TaskReview";
import AdminDashboard from "./components/RWADashboard/AdminDashboard";
import CsvFilePreview from "./components/RWADashboard/uploadCSVpreview";
import ReviewFormat from "./components/RWADashboard/reviewFormat";
import SignUp from "./components/onBoardingv2/Signup";
import Register from "./components/onBoardingv2/Register";
import GuestRoutes from "./components/GuestRoutes";
import PrivateRoutes from "./components/PrivateRoutes";
import UserData from "./pages/UserData";
import VerifyWhatsApp from "./components/onBoardingv2/VerifyWhatsApp";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndConditions";
import AnyRoutes from "./components/AnyRoute";
import AcceptInvitationPage from "./components/acceptInvitation/acceptInvitation";
import ManageSubordinates from "./components/manageUsers/ManageSubordinates";
import AcceptGigInvitationPage from "./components/acceptInvitation/acceptGigInvitation";
import CategoryDetails from "./pages/CategoryDetails";
import ScrollToTop from "./components/ScrollToTop";
import ExploreCategories from "./pages/ExploreCategories";
import Notifications from "./components/notifications/Notifications";
import ExternalRoutes from "./components/ExternalRoute";
import VendorContainer from "./VendorConatiner";
import VendorDashboard from "./vendor/dashboard/VendorDashboard";
import AuditUserData from "./pages/AuditUserData";
import SpoTrueLoginPage from "./pages/SpoTrueLoginPage";
import ForgotPasswordPageSpoTrue from "./components/forgetPasswordPageSpoTrue";
import SpoTruePrivacyPolicy from "./pages/SpotruePrivacyPolicy";
import SpoTrueTermsAndCondition from "./pages/SpoTrueTermsAndCondition";
import { useEffect } from "react";
import AuditCreationDialog from "./vendor/vehicleAdmin/AuditCreationDialog";
import AuditRequestPage from "./vendor/vehicleAdmin/AuditRequestPage";

function App() {
  const target = process.env.REACT_APP_TARGET;
  useEffect(() => {
    // Update the favicon dynamically
    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement | null;
    if (favicon) {
      favicon.href =
        target === "spotrue" ? `${process.env.PUBLIC_URL}/images/logos/spotrueCropped.png` : "/favicons/GoGig.png";
    }
  }, [target]);

  return (

    <>
      {target === "gogig" && <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/">
            <Route element={<SlimContainer />}>
              <Route path="/terms-conditions" element={<TermsAndCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Route>

            <Route element={<DefaultContainer />}>
              <Route element={<AnyRoutes />}>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/profile" element={<UserData />} />
                <Route
                  path="/companyRegistration"
                  element={<CompanyRegistration />}
                />
                <Route
                  path="/explore-categories"
                  element={<ExploreCategories />}
                />
                <Route path="/task/gigHistory" element={<GigHistoryList />} />
                <Route path="/employerGigList" element={<EmployerGigList />} />
                <Route path="/endUserLandingPage" element={<GigDetailsPage />} />
                <Route
                  path="/categoryDetails/:categoryId"
                  element={<CategoryDetails />}
                />
                <Route path="/gig-details/gigId" element={<GigDetailsEmp />} />
                <Route path="/vendors" element={<VendorList />} />
                <Route path="/vendors/:gigId" element={<VendorList />} />
                <Route path="/about" element={<AboutPageComponent />} />
                <Route path="/faq" element={<FAQPageComponent />} />
                <Route path="/browse-jobs" element={<ExploreCategories />} />
                <Route path="/CandidateDashBoard" element={<GigHistoryList />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/supplementsPage" element={<SupplementsPage />} />
                <Route
                  path="/employer-registration"
                  element={<EmployerRegistrationForm />}
                />
                <Route path="/daily-earnings" element={<DailyEarnings />} />
                <Route
                  path="/gig-result/:gigId/admin-dashboard"
                  element={<AdminDashboard />}
                />
                <Route
                  path="/gig-result/:gigId/upload-csv"
                  element={<CsvFilePreview />}
                />
                <Route
                  path="/gig-result/:gigId/view-format"
                  element={<ReviewFormat />}
                />
                <Route
                  path="/gig-result-pdf/:gigId"
                  element={<PdfTestComponent />}
                />
                <Route path="/createPin" element={<CreatePin />} />
                <Route path="/task-review/:gigId" element={<TaskReview />} />
                <Route path="/notifications" element={<Notifications />} />
              </Route>
            </Route>
          </Route>

          <Route path="/user-entry" element={<SlimContainer />}>
            <Route path="/user-entry" element={<UserEntry />} />
            <Route
              path="/user-entry/user-entry-form/:stepName"
              element={<UserEntryForm />}
            />
            <Route path="/user-entry/sign-up" element={<SignUpPage />} />

            <Route path="/user-entry/login" element={<LoginPage />} />
            <Route
              path="/user-entry/go-to-email-inbox"
              element={<GoToEmailInbox />}
            />
            <Route path="/user-entry/signup" element={<SignUp />} />

            <Route element={<GuestRoutes />}>
              <Route
                path="/user-entry/verifyWhatsappLogin/token/:token"
                element={<VerifyWhatsApp />}
              />
            </Route>

            <Route element={<PrivateRoutes />}>
              <Route path="/user-entry/register" element={<Register />} />
              <Route
                path="/user-entry/profile-created-page"
                element={<ProfileCreatedPage />}
              />
              <Route
                path="/user-entry/signup/verify-email/token/:token"
                element={<VerifyEmail />}
              />

              <Route
                path="/user-entry/forgot-password"
                element={<ForgotPasswordPage />}
              />
            </Route>
          </Route>

          <Route path="/task" element={<TaskContainer />}>
            <Route
              path="/task/vendorDetails/:gigId"
              element={<VendorDetails />}
            />
            <Route
              path="/task/gig/:gigId/:visibilityId"
              element={<GigDetailsPage />}
            />
          </Route>

          <Route path="/task/gig-status/:gigId" element={<GigStatus />} />

          <Route path="/payment" element={<TaskContainer />}>
            <Route path="/payment/aadhar-pancard" element={<AadharPanForm />} />
            <Route path="/payment/withdrawpage" element={<WithdrawPage />} />
            <Route path="/payment/withdraw-confirmation" element={<OTPPage />} />
            <Route path="/payment/withdraw-status" element={<WithdrawStatus />} />
            <Route path="/payment/payment-options" element={<PaymentMethod />} />
          </Route>

          <Route element={<TaskContainer />}>
            <Route path="/manage-subordinates" element={<ManageSubordinates />} />
            <Route
              path="/manage-subordinates/:gigId"
              element={<ManageSubordinates />}
            />
          </Route>
          <Route element={<SlimContainer />}>
            <Route
              path="/accept-invitation/:supervisorId"
              element={<AcceptInvitationPage />}
            />
            <Route
              path="/accept-invitation/:supervisorId/:gigId"
              element={<AcceptGigInvitationPage />}
            />
          </Route>
        </Routes>
      </Router>}
      {target === "spotrue" && <Router>
        <ScrollToTop />
        <Routes>

          {/* External Vendor */}
          <Route path="/" element={<VendorContainer />}>
            <Route element={<ExternalRoutes />}>
              <Route index element={<VendorDashboard />} />
            </Route>
            <Route
              path="/upload-createRequest"
              element={<AuditCreationDialog />}
            />
             <Route
              path="/upload-createRequest/:gogigReqId"
              element={<AuditCreationDialog />}
            />
            <Route
              path="/audit-process/:gogigReqId"
              element={<AuditRequestPage />}
            />
            <Route
              path="password/resetToken/:token"
              element={<NewPasswordPage />}
            />
            <Route path="profile" element={<AuditUserData />} />
            <Route path="sign-up" element={<SignUpPage />} />

            <Route path="login" element={<SpoTrueLoginPage />} />
            <Route path="/privacy-policy" element={<SpoTruePrivacyPolicy />} />
            <Route path="/terms-conditions" element={<SpoTrueTermsAndCondition />} />
            <Route path="go-to-email-inbox" element={<GoToEmailInbox />} />
            <Route path="register" element={<Register />} />
            <Route path="profile-created-page" element={<ProfileCreatedPage />} />
            <Route
              path="signup/verify-email/token/:token"
              element={<VerifyEmail />}
            />
            <Route
              path="forgot-password"
              element={<ForgotPasswordPageSpoTrue />}
            />
          </Route>
        </Routes>
      </Router>}
    </>
  );
}

export default App;
