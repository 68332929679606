import { AuthApiBaseUrl, clientId, SpotrueClientId } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function getAnonymousToken(): Promise<{ token: string }> {
  const target = process.env.REACT_APP_TARGET;
  const res = await fetchWrapper(
    `${AuthApiBaseUrl}/getAnonymousToken?clientId=${
      target === "spotrue" ? SpotrueClientId : clientId
    }`,
    {
      method: "GET",
    }
  );
  return res.data as { token: string };
}
