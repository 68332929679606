import { fetchWrapper } from "@gogig-in/gogig-clients";
import { LoginValues } from "../providers/types";
import { AuthApiBaseUrl, clientId,SpotrueClientId } from "./baseUrls";

export async function login({
  emailId,
  password,
  accessToken,
}: LoginValues): Promise<{ token: string }> {
  const target = process.env.REACT_APP_TARGET;
  const url = `${AuthApiBaseUrl}/login?clientId=${target==="spotrue"?SpotrueClientId:clientId}`;

  const res = await fetchWrapper(url, {
    method: "POST",
    body: {
      emailId,
      password,
    },
    headers: {
      Authorization: accessToken,
    },
  });
  return res.data as { token: string };
}