import Strings from "../../Locale/strings.json";
import { NavigationItem } from "./types";
export const auditGuestSideNavigationItems: NavigationItem[] = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Login",
    path: "/login",
  },
];
export const auditNavigationRoutes: NavigationItem[] = [
    {
      label: Strings.Header.Home,
      path: "/",
    },
  ];
  