import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Container,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./Signup.css";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import { getUserData } from "@gogig-in/gogig-clients";
import { OTP } from "./OtpInput";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomLoading from "../custom/Loading";
import { useAuthContext } from "../../providers/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useLocation } from "react-router-dom";
import { sendEvent } from "../../analytics/sendEvent";
import { sendVerificationRequest } from "@gogig-in/gogig-clients";

// const WhatsAppBaseNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

export function isNullOrEmpty(value: string | null | undefined) {
  return value === null || value === "";
}

const SignUp: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | number | any>("");
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState<boolean>(false);
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState<Boolean>(false); // Show
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<Severity>(undefined);
  const navigate = useNavigate();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(true); // Disable button if the timer
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { authHeader, loginWithPhoneNumber } = useAuthContext();
  const [timer, setTimer] = useState(15);
  const location = useLocation();
  const supervisorId = location.state?.supervisorId;
  const gigId = location.state?.gigId;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  //change length of OTP component
  const otpLength = 4;

  useEffect(() => {
    if (otp.length === otpLength) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [otp]);

  useEffect(() => {
    if (phoneNumberInputRef.current) {
      phoneNumberInputRef.current.focus();
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value.replace(/\s+/g, "");
    setPhoneNumberError("");

    if (value.length === 10) {
      setPhoneNumberError("");
    } else if (!/^\d{0,10}$/.test(value)) {
      setPhoneNumberError("Please enter a valid phone number");
    }
    // Check if input value contains alphabets
    if (/^[a-zA-Z]+$/.test(value)) {
      setPhoneNumberError("Phone number should not contain alphabets");
    }
    if (value.length === 10 && isNaN(Number(value[9]))) {
      setPhoneNumberError("Phone number should not contain alphabets");
    }
    if (value.length === 10) {
      const hasLetters = value.split("").some((char) => isNaN(Number(char)));
      if (hasLetters) {
        setPhoneNumberError("Phone number should not contain letters");
      }
    }
    if (value.startsWith("+91")) {
      setPhoneNumber(value.slice(3));
      if (value.slice(3).length === 10) {
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("enter valid phone number");
      }
    } else if (value.startsWith("0")) {
      setPhoneNumber(value.slice(1));
      setPhoneNumberError(""); 
    } else {
      setPhoneNumber(value);
    }
  };

  const validatePhoneNumber = (input: string) => {
    // Regular expression for validating phone number
    const phoneRegex = /[1-9]{1}[0-9]{9}/;
    if (input.length === 10) {
      setPhoneNumberError("");
      return true;
    } else if (input.length !== 10) {
      setPhoneNumberError("Enter a valid Phone number");
      return false;
    } else if (!phoneRegex.test(input)) {
      setPhoneNumberError("Invalid phone number");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };

  const validateOtp = (input: string) => {
    // You can add more specific validation for OTP if needed
    if (input.length !== 4) {
      setOtpError("OTP must be 4 digits");
      return false;
    } else {
      setOtpError("");
      return true;
    }
  };

  const handleLoginSubmit = (): void => {
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
    try {
      if (isPhoneNumberValid) {
        // Submit logic goes here
        setIsLoading(true);
        setTimer(15);
        if (authHeader) {
          sendVerificationRequest(phoneNumber, authHeader)
            .then(() => {
              handleOpenSnackbar();
              setShowOtpInput(true);
              setIsLoading(false);
              setOtp("");
              authHeader &&
                sendEvent(
                  {
                    eventBody: {
                      eventName: "phoneNumberLogin",
                      description: "log in attempt with phone number",
                      data: {},
                    },
                  },
                  authHeader
                );
            })
            .catch((error: any) => {
              setIsLoading(false);
              setOtpError(error);
              setOpenSnackbar(true);
              setSnackbarMessage(`error in OTP ${otpError}`);
              setSeverity("error");
            });
        } else {
          console.error("Form not submitted, please fix errors");
        }
      } else {
        console.error("Form not submitted, please fix errors");
      }
    } catch (e) {
      console.warn("Unexpected Error", e);
    }
  };

  const handleOpenSnackbar = () => {
    const phone = phoneNumber;
    setOpenSnackbar(true);
    setSnackbarMessage(`OTP sent to ${phone}`);
    setSeverity("success");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (otp: any) => {
    setOtp(otp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtpSubmit = async () => {
    try {
      setIsLoading(true);
      if (otp === "") {
        alert("Please enter OTP");
      } else if (validateOtp(otp) === false) {
        alert("Invalid OTP");
      } else {
        loginWithPhoneNumber(otp, phoneNumber).then((value) => {
          checkUserAndRedirect(value);
          setIsLoading(true);
        });
      }
    } catch (e: any) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(`${e.status}`);
      setSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  // const redirectToWhatsApp = () => {
  //   window.open(
  //     `https://api.whatsapp.com/send?phone=${WhatsAppBaseNumber}&text=` +
  //       encodeURIComponent("help me login"),
  //     "_blank"
  //   );
  // };

  const checkUserAndRedirect = async (authToken: string) => {
    try {
      if (authToken) {
        const response = await getUserData(`Bearer ${authToken}`);
        let res: any = response.data;
        if (supervisorId && gigId) {
          if (
            isNullOrEmpty(res.emailId) ||
            isNullOrEmpty(res.dob) ||
            isNullOrEmpty(res.firstName) ||
            isNullOrEmpty(res.lastName) ||
            isNullOrEmpty(res.gender)
          ) {
            navigate("/user-entry/register", {
              state: {
                supervisorId: supervisorId,
                gigId: gigId,
              },
            });
          } else {
            navigate(`/accept-invitation/${supervisorId}/${gigId}`);
          }
        } else if (supervisorId) {
          if (
            isNullOrEmpty(res.emailId) ||
            isNullOrEmpty(res.dob) ||
            isNullOrEmpty(res.firstName) ||
            isNullOrEmpty(res.lastName) ||
            isNullOrEmpty(res.gender)
          ) {
            navigate("/user-entry/register", {
              state: {
                supervisorId: supervisorId,
              },
            });
          } else {
            navigate(`/accept-invitation/${supervisorId}`);
          }
        } else {
          if (
            isNullOrEmpty(res.emailId) ||
            isNullOrEmpty(res.dob) ||
            isNullOrEmpty(res.firstName) ||
            isNullOrEmpty(res.lastName) ||
            isNullOrEmpty(res.gender)
          ) {
            navigate("/user-entry/register");
          } else {
            navigate("/home");
          }
        }
      } else {
        console.warn("authHeader is missing or invalid.");
      }
    } catch (e: any) {
      console.error("error during getUserData", e);
    }
  };

  return (
    <Grid>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thank You</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box>Phone number is verified sucessfully!</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <Box marginTop={5}>
          <Container
            id="customContainer"
            sx={{
              position: "relative",
              top: isLargeScreen ? "0" : "-70px",
              border: isLargeScreen ? "1px solid #ddd" : "none",
              borderRadius: "10px",
              padding: "20px",
            }}
            maxWidth={"xs"}
          >
            <Box textAlign="center" mb={2}>
              {/* <img src={login_ils} alt="Phone Login Illustration" style={{ height: '200px' }} /> */}
              <Typography
                variant="h2"
                textAlign="center"
                gutterBottom
                mx={2}
                my={2}
              >
                Welcome to gOGig
              </Typography>
              <Typography
                variant="h6"
                textAlign="center"
                gutterBottom
                mx={2}
                my={2}
              >
                Your Gateway to Flexible Work and Boundless Opportunities
              </Typography>
            </Box>
            {showOtpInput ? (
              <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  variant="h5"
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  my={2}
                >
                  Verify OTP sent to your phone number :
                  <br />
                  <span
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    +91 {phoneNumber}{" "}
                    <EditIcon
                      onClick={() => setShowOtpInput(false)}
                      fontSize="small"
                      style={{
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                >
                  <OTP
                    separator={"-"}
                    value={otp}
                    onChange={handleChange}
                    length={otpLength}
                  />
                </Box>
                <Grid item xs={12}>
                  {timer === 0 ? (
                    <Button onClick={handleLoginSubmit}>Resend OTP</Button>
                  ) : (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Please wait for{" "}
                      <span style={{ color: "#1976d2" }}>00:{timer}</span>{" "}
                      before resending the OTP.
                    </Typography>
                  )}
                </Grid>

                <Button
                  onClick={handleOtpSubmit}
                  ref={buttonRef}
                  variant="contained"
                  color="primary"
                  disabled={isBtnDisabled}
                >
                  Verify
                </Button>
                {/* <Typography variant="h4" textAlign="center" display="block">
                  Or
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    border: "2px solid #32a852",
                    background: "#fff",
                    color: "#32a852",
                  }}
                  onClick={() => {
                    redirectToWhatsApp();
                  }}
                >
                  <WhatsAppIcon />
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    What's app
                  </span>
                </Button> */}
              </Box>
            ) : (
              // Phone Number Entry //
              <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography
                  variant="h4"
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  my={2}
                >
                  Login / Register
                </Typography>
                <TextField
                  label="Phone Number"
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  fullWidth
                  inputRef={phoneNumberInputRef}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  autoComplete="tel"
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                />

                <Typography
                  textAlign="start"
                  gutterBottom
                  mx={2}
                  marginBottom={4}
                >
                  By continuing, you agree to our{" "}
                  <Link
                    to={"/privacy-policy"}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        color: "blue",
                        // textDecoration:"underline"
                      }}
                      color="primary"
                    >
                      Privacy Policy
                    </span>
                  </Link>{" "}
                  and{" "}
                  <Link
                    to={"/terms-conditions"}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        color: "blue",
                        // textDecoration:"underline"
                      }}
                    >
                      Terms & Conditions
                    </span>
                  </Link>
                  .
                </Typography>
                <Button
                  onClick={handleLoginSubmit}
                  ref={buttonRef}
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
                {/* Show Google Button only if not in production */}
                {/* <Typography variant="h4" textAlign="center" display="block">
                  Or
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    border: "2px solid #32a852",
                    background: "#fff",
                    color: "#32a852",
                  }}
                  onClick={() => {
                    authHeader &&
                      sendEvent(
                        {
                          eventBody: {
                            eventName: "whatsAppLogin",
                            description: "log in attempt with Whatsapp",
                            data: {},
                          },
                        },
                        authHeader
                      );
                    redirectToWhatsApp();
                  }}
                >
                  <WhatsAppIcon />
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    What's app
                  </span>
                </Button> */}
              </Box>
            )}
          </Container>
        </Box>
      )}
    </Grid>
  );
};

export default SignUp;
