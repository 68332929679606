import React, { useState } from "react";
import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  IconButton,
  StepConnector,
  styled,
  Popover,
} from "@mui/material";
import { Info, InfoOutlined, PlayCircle } from "@mui/icons-material";
import { CurrentAndNextStepInfo } from "./CurrentAndNextStepInfo";

interface RequestStatusProgressProps {
  requestStatus: string;
  dueDate?: string; // Optional due date for processing completion
  allowRequestEdit?: boolean; // Optional flag for rejected requests
  comments?: string; // Optional comments for rejected requests
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    height: "50px",
    borderWidth: 3, // Increase line thickness
    borderColor: theme.palette.grey[400], // Set color for the line
  },
}));
const StyledNote = styled("div")(({ theme }) => ({
  backgroundColor: "#FFF9C4", // Light yellow background (like a note)
  border: "1px solid #E0E0E0", // Light gray border for subtle distinction
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1], // Slight shadow to make it look like a note
  fontStyle: "italic", // Optional: to make the text resemble a note
  maxWidth: "100%", // Ensures it fits within its container
  wordWrap: "break-word", // Prevents long words from overflowing
  marginTop: theme.spacing(2), // Adds space above the note
}));
const RequestStatusProgress: React.FC<RequestStatusProgressProps> = ({
  requestStatus,
  dueDate,
  allowRequestEdit,
  
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [showDescription, setShowDescription] = useState(false);
  const colors = {
    completed: "#4CAF50",
    inProgress: "#FF9800",
    notStarted: "#9E9E9E",
    rejected: "#D32F2F",
    default: "#B0BEC5",
  };
  const statusSteps = [
    "Request Created",
    "Request UnderReview",
    requestStatus === "rejected" ? "Request Rejected" : "Request Approved",
    "Processing",
    "Results Under Review",
    "Completed",
  ];
  const statusStepDetails = [
    {
      label: "Request Created",
      descriptions: [
       
        {
          status: [
            "requestCreated",
            "processing",
            "resultsUnderReview",
          "requestUnderReview",
            "completed",
            "rejected",
            "requestApproved",
          ],
          color: colors.completed,
          description: "Your request has been successfully created",
        },
      ],
    },
    {
      label: "Request Under Review",
      descriptions: [
        {
          status: "requestCreated",
          color: colors.notStarted,
          description: "The review process has not started yet.",
        },
        {
          status: "requestUnderReview",
          color: colors.inProgress,
          description:
            "The review process for your audit request has now begun!",
        },
        {
          status: [
            "processing",
            "resultsUnderReview",
            "completed",
            "rejected",
            "requestApproved",
          ],
          color: colors.completed,
          description:
            "The review of your audit request has been successfully completed!",
        },
      ],
    },
    {
      label:
        requestStatus === "rejected" ? "Request Rejected" : "Request Approved",
      descriptions: [
        {
          status: "requestCreated",
          color: colors.notStarted,
          description: "The review process has not started yet.",
        },
        {
          status: "requestUnderReview",
          color: colors.notStarted,
          description:
            "The review process for your audit request has now begun!.",
        },
        {
          status: "requestApproved",
          color: colors.completed,
          description: "Your request has been approved! .",
        },
        {
          status: "rejected",
          color:allowRequestEdit?colors.inProgress: colors.rejected,
          description: allowRequestEdit
            ? "Your request has been commented on."
            : "Your request has been rejected due to incorrect files.",
        },
        {
          status: ["processing", "resultsUnderReview", "completed"],
          color: colors.completed,
          description: "your request for auditing has been approved",
        },
      ],
    },
    {
      label: "Processing",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestUnderReview",
            "rejected",
            "requestApproved",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, your request auditing process will begin.",
        },
        {
          status: "processing",
          color: colors.inProgress,
          description: "The auditing process for your request has now begun.",
        },
        {
          status: ["resultsUnderReview", "completed"],
          color: colors.completed,
          description:
            "The auditing process for your request has been successfully completed.",
        },
      ],
    },
    {
      label: "Results Under Review",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestUnderReview",
            "rejected",
            "requestApproved",
            "processing",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, the review of your request auditing results will begin.",
        },
        {
          status: "resultsUnderReview",
          color: colors.inProgress,
          description:
            "The review of your audit request results has officially begun! ",
        },
        {
          status: "completed",
          color: colors.completed,
          description: "You can now download your report. Thank you!",
        },
      ],
    },
    {
      label: "Completed",
      descriptions: [
        {
          status: [
            "requestCreated",
            "requestUnderReview",
            "rejected",
            "requestApproved",
            "processing",
            "resultsUnderReview",
          ],
          color: colors.notStarted,
          description:
            "Once the above steps are completed, you will be able to download the report.",
        },
        {
          status: "completed",
          color: colors.completed,
          description: "You can now download your report. Thank you!",
        },
      ],
    },
  ];
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedStep(index);
    setAnchorEl(event.currentTarget);
    setShowDescription((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStep(null);
    setShowDescription(false);
  };

  const getStatusStepInfo = (stepIndex: number, status: string) => {
    const step = statusStepDetails[stepIndex];
    const descriptionObj =
      step.descriptions.find((d) =>
        Array.isArray(d.status)
          ? d.status.includes(status)
          : d.status === status
      ) || step.descriptions.find((d) => d.status === "default");

    return {
      description: descriptionObj?.description,
      color: descriptionObj?.color,
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row",sm:"row", xs: "column" },
        alignItems: "center",
        padding: 2,
        width: "100%", // Ensure it doesn't cause overflow
        overflowX: "hidden", // Prevent horizontal scrolling
        overflowY: "auto", // Allow vertical scrolling if needed
      }}
    >
      <Stepper
        orientation="vertical"
        activeStep={-1}
        connector={<CustomConnector />}
        sx={{
          width: "100%", // Make sure the Stepper doesn't overflow
          maxWidth: "100%", // Ensure it fits within its container
        }}
      >
        {statusSteps.map((step, index) => {
          const { color } = getStatusStepInfo(index, requestStatus);
          return (
            <Step key={index}>
              <StepLabel
                StepIconProps={{
                  icon: React.cloneElement(<PlayCircle />, { sx: { color } }),
                }}
              >
                <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: color,
                      padding: "6px 12px",
                      borderRadius: "8px",
                      marginBottom: "4px",
                      wordBreak: "break-word", // Prevents long words from overflowing
                    }}
                  >
                    {step}
                  </Typography>
                  <IconButton
                    onClick={(event) => handleClick(event, index)}
                    size="small"
                  >
                    {showDescription && selectedStep === index ? (
                      <Info sx={{ color, marginRight: 1 }} />
                    ) : (
                      <InfoOutlined sx={{ color, marginRight: 1 }} />
                    )}
                  </IconButton>
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Popover
        open={Boolean(anchorEl) && showDescription}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          p: 2,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "600px", // Limit the max width of the Popover
          width: "auto", // Ensure the width adjusts to content
          // Prevent horizontal overflow
        }}
      >
        {selectedStep !== null ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="body2">
              {getStatusStepInfo(selectedStep, requestStatus).description}
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </Popover>

      <StyledNote>
        <CurrentAndNextStepInfo
          status={requestStatus}
          allowRequestEdit={allowRequestEdit}
          dueDate={dueDate}
        />
      </StyledNote>
    </Box>
  );
};

export default RequestStatusProgress;
