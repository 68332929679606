import { fetchWrapper } from "@gogig-in/gogig-clients";
import { AuthApiBaseUrl, clientId, SpotrueClientId } from "./baseUrls";

export async function resendEmailVerification(emailId: string) {
  const target = process.env.REACT_APP_TARGET;
  const requestBody = {
    emailId,
  };
  return fetchWrapper(
    `${AuthApiBaseUrl}/resendVerificationEmail?clientId=${
      target === "spotrue" ? SpotrueClientId : clientId
    }`,
    {
      method: "PATCH",
      body: requestBody,
    }
  );
}
