import { Box, Avatar, Typography, Button } from "@mui/material";
import React from "react";
import { useAuthContext } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

interface LoggedInUserInfoProps {
  redirect: string; // Prop to define where to redirect after logout
}

const LoggedInUserInfo: React.FC<LoggedInUserInfoProps> = ({ redirect }) => {
  const { logout, userDetails } = useAuthContext();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 4,
        backgroundColor: "#f5f5f5",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "500px",
          width: "100%",
          padding: "40px",
          borderRadius: "12px",
          backgroundColor: "#ffffff",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Avatar
          alt={userDetails?.firstName || "User"}
          src={userDetails?.profilePicture || ""}
          sx={{
            width: 120,
            height: 120,
            margin: "0 auto",
            marginBottom: "20px",
            backgroundColor: userDetails?.profilePicture
              ? "transparent"
              : "#1976d2",
            color: "#fff",
            fontSize: "3rem",
            border: "5px solid #fff",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          {userDetails?.firstName?.[0]?.toUpperCase() || "U"}
        </Avatar>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            marginBottom: "15px",
            color: "#333",
          }}
        >
          {`Hello, ${userDetails?.firstName || "User"}!`}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: "30px",
            fontSize: "1rem",
            lineHeight: "1.5",
            color: "#666",
          }}
        >
          You are currently logged in. If this is not you, please log out by
          clicking the button below.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
         
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              padding: "12px 30px",
              borderRadius: "8px",
              fontSize: "1rem",
              fontWeight: "bold",
              boxShadow: "0 6px 12px rgba(0, 123, 255, 0.2)",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              textTransform: "none",
              padding: "12px 30px",
              borderRadius: "8px",
              fontSize: "1rem",
              fontWeight: "bold",
              boxShadow: "0 6px 12px rgba(255, 0, 0, 0.2)",
            }}
            onClick={() => {
              logout({ returnTo: redirect });
            }}
          >
            Log Out
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoggedInUserInfo;
